<app-table-generator
    [tableInput]="tableGenInput"
    [widgetRef]="widgetRef"
    (gridRef)="agGrid = $event"
    (selectionChanged)="onSelectionChanged()"
    [showPagination]="true"
></app-table-generator>

<ng-template #actionTemplate>
    <li class="action-text" *ngFor="let data of selectedData">
        <span *ngIf="data && data.accountName">{{ data.accountName }}</span>
        <span *ngIf="data && data.accountNumber">
            {{ data.accountNumber }}
        </span>
    </li>
</ng-template>

<ng-template #confirmationModal>
    <div class="content-modal-wrapper">
        <p>
            <ng-container [ngTemplateOutlet]="modalMessage"></ng-container>
        </p>
        <ul class="account-list-ul">
            <li *ngFor="let item of accountsToBeConverted">
                {{ item["accountName"] }} {{ item["accountNumber"] }}
            </li>
        </ul>
        <div class="tw-flex tw-items-center tw-mb-2">
            <p class="open-modal-btn tw-m-0" (click)="openImpactModal()">
                Click here to view impact on console.
            </p>
            <span *ngIf="impactModalLoader" class="tw-ml-3">
                <mat-spinner color="accent" [diameter]="16"></mat-spinner>
            </span>
        </div>
        <p class="warning-text">
            Note : Once this action is performed, your
            {{
                accountsToBeConverted.length > 1
                    ? "subscriptions"
                    : "subscription"
            }}
            will be converted to Pay As You Go. All the details of
            {{ accountsToBeConverted.length > 1 ? "these" : "this" }}
            {{
                accountsToBeConverted.length > 1
                    ? "subscriptions"
                    : "subscription"
            }}
            will reflect under Pay As You Go Accounts.
        </p>
    </div>
</ng-template>

<ng-template #moreInfoWarningMessage>
    <div class="more-info-message-container">
        <div class="merge-container">
            <div appIcon [data]="warningIcon"></div>
            <ng-container [ngTemplateOutlet]="modalMessage"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #modalMessage>
    <span
        >Your Sponsorship
        {{
            accountsToBeConverted.length > 1 ? "subscriptions" : "subscription"
        }}
        has been converted to Pay As You Go
        {{
            accountsToBeConverted.length > 1 ? "subscriptions" : "subscription"
        }}. Please perform convert action to update your
        {{
            accountsToBeConverted.length > 1 ? "subscriptions" : "subscription"
        }}
        on Centilytics.</span
    >
</ng-template>
