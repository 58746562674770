import { NgClass, NgStyle } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    Input,
    NgZone,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Helper } from 'src/app/shared/classes/Helper';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { ViewType } from 'src/app/shared/enums/ViewType';
import { ButtonColorType } from '../../enums/ButtonColorType';
import { ICardsGeneratorInput } from './../../interfaces/card-generator/ICardsGeneratorInput';
import { HttpService } from '../../services/http/http-main/http.service';
import { TableSearchComponent } from '../table-search/table-search.component';
import { HitApi } from '../../classes/HitApi';
import { AuthorizationType } from '../../enums/AuthorizationType';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { PaginationValues } from '../../enums/PaginationValues';


@Component({
    selector: 'app-cards-generator',
    templateUrl: './cards-generator.component.html',
    styleUrls: ['./cards-generator.component.sass'],
})
export class CardsGeneratorComponent implements OnInit {
    @ViewChild(TableSearchComponent) tableSearchComponent: TableSearchComponent;
    @Input() cardInput: ICardsGeneratorInput;
    @Input() widgetRef: Widget;
    @Input() cardInfoTemplate: TemplateRef<any>;
    @Input() cardInfoTemplateStyles: NgStyle;
    @Input() cardInfoTemplateClasses: NgClass;
    // This input property controls the visibility of pagination in the widget
    @Input() showPagination?: boolean;

    ButtonColorType = ButtonColorType;
    cardsData = null;
    filteredCardsData = null;
    Helper = Helper;
    selectAll = false;
    bulkLoader = new BehaviorSubject<boolean>(false);

    selectionChange = true;
    totalSelectedCards = 0;
    public paginationValues: {
        startItem: number;
        endItem: number;
        totalRecords: number;
        totalPages: number;
    } = {
        startItem: 0,
        endItem: 0,
        totalRecords: 0,
        totalPages: 0,
    };
    public inputValue = 1;
    public selectedOption = 20;
    public pageNumber = 1;
    private prevPageNumber = 1;
    private searchedValue = '';
    constructor(
        private httpService: HttpService,
        private ngZone: NgZone,
        private notificationService: NotificationsService,
        private changeDetector: ChangeDetectorRef
    ) {}
    ngOnInit(): void {
        this.setUpBasics();
    }

    ngAfterViewInit(): void {
        if (this.showPagination) {
            this.navigatePage();
        } else {  
            this.widgetRef.setBindData(this.bindData.bind(this));
        }
    }
    private timeOut:number = 0;
    onPageChange() {
        if(this.timeOut){
            clearTimeout(this.timeOut)
        }
        this.timeOut = window.setTimeout(()=>{
            if (
                this.pageNumber > this.paginationValues.totalPages ||
                !this.pageNumber
            ) {
                this.notificationService.showSnackBar(
                    'Entered Page Number should be less than total pages present',
                    true
                );
                this.pageNumber = this.prevPageNumber;
                this.changeDetector.detectChanges()
                return;
            }
            else if(this.pageNumber<=0){
                this.notificationService.showSnackBar(
                    'Entered Page Number must be a positive number',
                    true
                );
                this.pageNumber = this.prevPageNumber;
                this.changeDetector.detectChanges()
                return;
            } else {
                this.navigatePage();
            }
        },1000);
    }

    /**
     * function handles pagination for widgets in which pagination functionality is implemented
     * @param value  - reflects the action like nextPage, previousPage, lastPage or firstPage
     */
    navigatePage(value?: string | number) {
            if (value === PaginationValues.NEXTPAGE) {
                if (this.paginationValues.totalPages === this.pageNumber) {
                    return;
                } else {
                    this.pageNumber = this.pageNumber + 1;
                }
            }
            if (value === PaginationValues.PREVIOUSPAGE) {
                if (this.pageNumber === 1) {
                    return;
                } else {
                    this.pageNumber = this.pageNumber - 1;
                }
            }
            if (value === PaginationValues.FIRSTPAGE) {
                if (this.pageNumber === 1) {
                    return;
                } else {
                    this.pageNumber = 1;
                }
            }
            if (value === PaginationValues.LASTPAGE) {
                if (this.paginationValues.totalPages === this.pageNumber) {
                    return;
                } else {
                    this.pageNumber = this.paginationValues.totalPages;
                }
            }
        this.prevPageNumber = this.pageNumber
        if (this.searchedValue) {
            this.onPaginationSearch(this.searchedValue);
        } else {
            this.widgetRef.onChangePagination(
                this.bindData.bind(this),
                this.pageNumber,
                this.selectedOption
            );
        }
    }

    setUpBasics() {
        // Show View Icon
        // this.widgetRef.showViewIcon.next(true);

        // Update Header Icon
        if (this.cardInput.widgetIconData) {
            this.widgetRef.headerIcon.next(this.cardInput.widgetIconData);
        }

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = this.widgetRef.visibleSections
            .value
            ? this.widgetRef.visibleSections.value
            : new Set();
        visibleSections.add(ViewType.CARDS);
        this.widgetRef.visibleSections.next(visibleSections);
    }

    bindData(data): void {
        if (data) {
            if (this.showPagination) {
                this.paginationValues.startItem = data?.startItem;
                this.paginationValues.endItem = data?.endItem;
                this.paginationValues.totalRecords = data?.totalElements;
                this.paginationValues.totalPages = data?.totalPages;
                this.pageNumber = data?.currentPage;
                this.selectedOption = data?.pageSize;
                if(this.searchedValue) {
                    this.selectedOption = 20;
                    this.searchedValue = '';
                }
            }
            // if (
            //     this.cardInput.titleActions &&
            //     this.cardInput.titleActions.length
            // ) {
            //     this.cardInput.titleActions.forEach((titleAction) => {
            //         if (titleAction.showLoader) {
            //             titleAction.loader = false;
            //         }
            //     });
            // }
            if (
                this.cardInput.cardButtons &&
                this.cardInput.cardButtons.length
            ) {
                this.cardInput.cardButtons.forEach((cardButton) => {
                    if (cardButton.showLoader) {
                        cardButton.loader = false;
                    }
                });
            }
            this.widgetRef.apiResponse = this.showPagination
                ? data['content']
                : data;
            this.selectAll = false;
            this.totalSelectedCards = 0;

            if (this.cardInput.afterResponse) {
                this.cardInput.afterResponse(
                    this.showPagination ? data['content'] : data
                );
            }

            if (this.cardInput.listExtraction.type === 'DIRECT') {
                this.cardsData = this.showPagination ? data['content'] : data;
            } else {
                this.cardsData = Helper.extractDataFromObject(
                    this.cardInput.listExtraction.nestedKey,
                    this.showPagination ? data['content'] : data
                );
            }

            if (!this.cardsData || !this.cardsData.length) {
                this.widgetRef.extraMessage.next(
                    this.cardInput.noDataMessage
                        ? this.cardInput.noDataMessage
                        : Messages.NO_DATA_AVAILABLE
                );
                this.widgetRef.visibleSections.next(new Set());
                this.widgetRef.attentionRequired(
                    this.showPagination ? data['content'] : data
                );
                this.widgetRef.endLoader();
                return;
            }

            if (this.cardInput.selection === 'multiple') {
                this.selectAll = true;
                this.toggleSelection();
            }

            this.filteredCardsData = this.cardsData;
            this.widgetRef.endLoader();
        }
    }

    /**
     * Enables search functionality for widgets in which pagination is implemented
     * @param value - it reflects the searched value.
     */
    onPaginationSearch(value:string) {
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.list
        );
        apiArgs.url = apiArgs.url.replace(
            '{pageNo}/{offset}',
            `${this.pageNumber}/${this.selectedOption}?search=${value}`
        );
        apiArgs.config = {
            authorization: AuthorizationType.BEARER_TOKEN,
            defaultHeaders: {
                'Content-Type': 'application/json',
            },
        };
        apiArgs.function = (response) => {
            this.searchedValue = value;
            this.paginationValues.startItem = response?.startItem;
            this.paginationValues.endItem = response?.endItem;
            this.paginationValues.totalRecords = response?.totalElements;
            this.paginationValues.totalPages = response?.totalPages;
            this.filteredCardsData = response['content'];
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        apiArgs.errorFunction = (error) => {
            this.paginationValues.startItem = 0;
            this.paginationValues.endItem = 0;
            this.paginationValues.totalRecords = 0;
            this.paginationValues.totalPages = 0;
            this.filteredCardsData = [];
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        new HitApi(apiArgs, this.httpService, this.ngZone).hitApi();
    }

    toggleSelection() {
        if (this.selectAll) {
            // Deselect All
            this.cardsData = this.cardsData.map((card) => {
                card.CGselected = false;
                return card;
            });
            this.selectAll = false;
        } else {
            // Select All
            this.cardsData = this.cardsData.map((card) => {
                card.CGselected = true;
                return card;
            });
            this.selectAll = true;
        }
        this.selectionChange = true;
        this.updateSelectedCardsCount();
    }

    getSelectedCards() {
        return this.cardsData.filter((card) => card.CGselected);
    }

    updateSelectedCardsCount() {
        if (this.selectionChange) {
            const selectedCards = this.getSelectedCards();
            this.selectionChange = false;
            this.totalSelectedCards = selectedCards.length;
            return selectedCards.length;
        }
    }

    handleCardClick(card) {
        card.CGselected = !card.CGselected;
        this.selectionChange = true;
        this.updateSelectedCardsCount();
    }
    
    public isDropDownVisible:boolean = false;
    public paginationSelectedOptionsList:number[] = [20,25,30,35,40]
    handleToggleDropdown(){
        this.isDropDownVisible = !this.isDropDownVisible;
    }
    handleSelectOption(selectedOption:number){
        this.isDropDownVisible = false;
        if(this.selectedOption === selectedOption){
            return;
        }
        // if start item is greater than than total elements
        else if((((this.pageNumber-1)*(selectedOption))+1)>this.paginationValues.totalRecords){
            this.notificationService.showSnackBar('Invalid option Selected',true);
            return;
        }
        this.selectedOption = selectedOption;
        this.navigatePage()
    }
}
